/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #cfd8dc;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b0bec5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #90a4ae;
}

.custom-quill-editor .ql-editor {
  min-height: 30vh;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.8); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30; /* Set a high z-index to appear on top */
}

/* Sapphire Background Colors */
.bg-sapphire-50 {
  background-color: #edf5ff;
}
.bg-sapphire-100 {
  background-color: #d6e7ff;
}
.bg-sapphire-200 {
  background-color: #b6d7ff;
}
.bg-sapphire-300 {
  background-color: #84bfff;
}
.bg-sapphire-400 {
  background-color: #4a9cff;
}
.bg-sapphire-500 {
  background-color: #2072ff;
}
.bg-sapphire-600 {
  background-color: #084eff;
}
.bg-sapphire-700 {
  background-color: #0238f3;
}
.bg-sapphire-800 {
  background-color: #092ec4;
}
.bg-sapphire-900 {
  background-color: #0e2d9a;
}
.bg-sapphire-950 {
  background-color: #102169;
}

/* Sapphire Text Colors */
.text-sapphire-50 {
  color: #edf5ff;
}
.text-sapphire-100 {
  color: #d6e7ff;
}
.text-sapphire-200 {
  color: #b6d7ff;
}
.text-sapphire-300 {
  color: #84bfff;
}
.text-sapphire-400 {
  color: #4a9cff;
}
.text-sapphire-500 {
  color: #2072ff;
}
.text-sapphire-600 {
  color: #084eff;
}
.text-sapphire-700 {
  color: #0238f3;
}
.text-sapphire-800 {
  color: #092ec4;
}
.text-sapphire-900 {
  color: #0e2d9a;
}
.text-sapphire-950 {
  color: #102169;
}

/* Background Colors */
.bg-yellow-50 {
  background-color: #fffeea;
}
.bg-yellow-100 {
  background-color: #fff9c5;
}
.bg-yellow-200 {
  background-color: #fff385;
}
.bg-yellow-300 {
  background-color: #ffe746;
}
.bg-yellow-400 {
  background-color: #ffd61b;
}
.bg-yellow-500 {
  background-color: #ffb704;
}
.bg-yellow-600 {
  background-color: #e28b00;
}
.bg-yellow-700 {
  background-color: #bb6202;
}
.bg-yellow-800 {
  background-color: #984b08;
}
.bg-yellow-900 {
  background-color: #7c3e0b;
}
.bg-yellow-950 {
  background-color: #481f00;
}

/* Text Colors */
.text-yellow-50 {
  color: #fffeea;
}
.text-yellow-100 {
  color: #fff9c5;
}
.text-yellow-200 {
  color: #fff385;
}
.text-yellow-300 {
  color: #ffe746;
}
.text-yellow-400 {
  color: #ffd61b;
}
.text-yellow-500 {
  color: #ffb704;
}
.text-yellow-600 {
  color: #e28b00;
}
.text-yellow-700 {
  color: #bb6202;
}
.text-yellow-800 {
  color: #984b08;
}
.text-yellow-900 {
  color: #7c3e0b;
}
.text-yellow-950 {
  color: #481f00;
}

/* Background color classes */
.bg-tundora-50 {
  background-color: #f6f5f5;
}
.bg-tundora-100 {
  background-color: #e7e6e6;
}
.bg-tundora-200 {
  background-color: #d2cfd0;
}
.bg-tundora-300 {
  background-color: #b2aeaf;
}
.bg-tundora-400 {
  background-color: #8b8585;
}
.bg-tundora-500 {
  background-color: #706a6a;
}
.bg-tundora-600 {
  background-color: #605a5a;
}
.bg-tundora-700 {
  background-color: #504c4c;
}
.bg-tundora-800 {
  background-color: #464444;
}
.bg-tundora-900 {
  background-color: #3e3b3b;
}
.bg-tundora-950 {
  background-color: #272625;
}

/* Text color classes */
.text-tundora-50 {
  color: #f6f5f5;
}
.text-tundora-100 {
  color: #e7e6e6;
}
.text-tundora-200 {
  color: #d2cfd0;
}
.text-tundora-300 {
  color: #b2aeaf;
}
.text-tundora-400 {
  color: #8b8585;
}
.text-tundora-500 {
  color: #706a6a;
}
.text-tundora-600 {
  color: #605a5a;
}
.text-tundora-700 {
  color: #504c4c;
}
.text-tundora-800 {
  color: #464444;
}
.text-tundora-900 {
  color: #3e3b3b;
}
.text-tundora-950 {
  color: #272625;
}

.red {
  border: 1px solid red;
}


.phone-number-input {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.phone-number-tags {
  border: 2px solid #d1d5db;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
}

.phone-number-count {
  font-size: 14px;
  color: #555;
}
